export default class PsConfig {

    static versionNo : String = '1.3';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    static fixedDomain: String = "http://ahdthalsayarat.com/flutter-admotors-admin/admin"; 
    static domain: String = "http://ahdthalsayarat.com/flutter-admotors-admin/admin"; 

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    // static fixedDomain: String = "http://ahdthalsayarat.com/flutter-admotors-admin/admin";    
    // static domain: String = "http://localhost:3000";

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
        apiKey: "AIzaSyCl5m-MqaOL6G6G-we-t4WSQWed6QF6Bic",
        authDomain: "ahdthalsayarat-7b017.firebaseapp.com",
        projectId: "ahdthalsayarat-7b017",
        storageBucket: "ahdthalsayarat-7b017.appspot.com",
        messagingSenderId: "874950672883",
        appId: "1:874950672883:web:29d554dfe07bd6c4d65286",
        measurementId: "G-QBQZZSDG50"
      };
    
    // static firebaseConfig = {
    //     apiKey: "AIzaSyCCbP35UgAsfdnJaQM4TxWDLICYR4gDi28",
    //     authDomain: "flutter-admotors.firebaseapp.com",
    //     databaseURL: "https://flutter-admotors.firebaseio.com",
    //     projectId: "flutter-admotors",
    //     storageBucket: "flutter-admotors.appspot.com",
    //     messagingSenderId: "959099436187",
    //     appId: "1:959099436187:web:915d817bd3b509b1b81a3e"
    // };
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
    
    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = false;

    /// Is Demo
    ///
    static is_demo : Boolean = false;

}
